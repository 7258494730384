<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="row justify-content-start">
        <div *ngFor="let block of blocks" [class]="'col-md-' + block.colWidth + ' col-12'" [ngStyle]="{'display': block.buttonRow ? 'contents' : ''}">
            <!-- Header, divider, breaker -->
            <ng-container *ngIf="block.blockType == 'header'" [ngTemplateOutlet]="header" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container> 
            <ng-container *ngIf="block.blockType == 'body'" [ngTemplateOutlet]="body" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container>                                             
            <ng-container *ngIf="block.blockType == 'divider'" [ngTemplateOutlet]="divider"></ng-container>
            <ng-container *ngIf="block.blockType == 'breaker'" [ngTemplateOutlet]="breaker"></ng-container>
            <!-- Buttons -->
            <ng-container *ngIf="block.blockType == 'saveButton'" [ngTemplateOutlet]="saveButton" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container>
            <ng-container *ngIf="block.blockType == 'dynamicButton'" [ngTemplateOutlet]="dynamicButton" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container>
            <ng-container *ngIf="block.blockType == 'acceptRejectButton'" [ngTemplateOutlet]="acceptRejectButton" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container>
            <ng-container *ngIf="block.blockType == 'accept-Amend&Accept-Reject-Button'" [ngTemplateOutlet]="acceptAmendAndAcceptRejectButton" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container>
            <ng-container *ngIf="block.blockType == 'changesButton'" [ngTemplateOutlet]="changesButton" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container>
            <ng-container *ngIf="block.blockType == 'defaultButton'" [ngTemplateOutlet]="defaultButton" [ngTemplateOutletContext]="{ $implicit: block }"></ng-container>
            <!-- Input, select -->
            <app-dynamic-form-input *ngIf="block.blockType == 'input'" [form]="form" [block]="block"></app-dynamic-form-input>
            <app-dynamic-form-search-input *ngIf="block.blockType == 'searchInput'" [form]="form" [block]="block"></app-dynamic-form-search-input>
            <app-dynamic-form-dropdown *ngIf="block.blockType == 'searchDropdown'" [form]="form" [block]="block" [data]="data" (buttonClicked)="buttonClicked($event)" (optionSelected)="optionSelected($event)" [section]="parentSection"></app-dynamic-form-dropdown>
            <app-dynamic-form-select *ngIf="block.blockType == 'select'" [form]="form" [block]="block" (buttonClicked)="buttonClicked($event)" [data]="data"></app-dynamic-form-select>
            <app-dynamic-form-textarea *ngIf="block.blockType == 'textarea'" [form]="form" [block]="block"></app-dynamic-form-textarea>
            <!-- Array -->
            <app-dynamic-form-array *ngIf="block.blockType == 'array'" [form]="form" [block]="block" [data]="data" [rules]="rules" (removeItem)="removeItem($event)" (id)="idCheck($event)"></app-dynamic-form-array>
            <!-- Pending User(Roles/Products) -->
            <div *ngIf="block.blockType == 'pendingUser'">
                <app-roles-products-table [removeActionColumn]="false" [tableData]="tableData" [roles]="roles" [products]="products"></app-roles-products-table>
                <div class="mb-5" *ngIf="parentSection == 'user-pending'">
                    <button *ngIf="!tableData" (click)="openRolesSlider()" type="submit" class="btn btn-primary">Add roles</button>
                    <button *ngIf="tableData" (click)="openRolesSlider()" type="submit" class="btn btn-primary">Add/Remove roles</button>
                </div>
            </div>
            <!-- Nothing to display -->
            <ng-container *ngIf="block.noData">
                <p class="text-muted mt-3">
                    There is nothing to display in this view.
                </p>
            </ng-container>
        </div>
        <div *ngIf="rules">
            <button type="submit" class="btn btn-success mr-3" [hidden]="!form.valid || !form.dirty">Submit</button>
            <button type="button" [class]="!(setRule) ? 'btn btn-primary mr-2' : 'btn btn-secondary mr-2'" [disabled]="setRule" (click)="addItem(rules.dataKey)">Add</button>
        </div>
    </div>
</form>

<!-- Header Template -->
<ng-template #header let-block>
    <div class="pb-2" *ngIf="{ darkMode: darkMode$ | async } as ui">
        <h4 [innerHtml]="block.label" [ngClass]="{'text-primary': !ui.darkMode, 'text-primary-light': ui.darkMode}"></h4>
        <h5 class="text-secondary" [innerHtml]="block.helpText"></h5>
    </div>
</ng-template>

<!-- Body Template -->
<ng-template #body let-block>
    <div [innerHtml]="block.label"></div>
</ng-template>

<!-- Divider Template -->
<ng-template #divider>
    <div class="mt-2 mb-4">
        <hr />
    </div>
</ng-template>

<!-- Breaker Template -->
<ng-template #breaker>
    <div class="col-12"></div>
</ng-template>

<!-- Dynamic Button Template -->
<ng-template #dynamicButton let-block>
    <button (click)="registerButtonClick()" type="submit" class="btn btn-success">{{ block.label }}</button>
</ng-template>

<!-- Accept/Reject Button Template -->
<ng-template #acceptRejectButton let-block>
    <button (click)="acceptOrReject('accept')" type="submit" class="btn btn-success">Accept</button>
    <button (click)="acceptOrReject('reject')" type="submit" class="btn btn-danger ml-3">Reject</button>
</ng-template>

<!-- Accept/Amend and Accept/Reject Button Template -->
<ng-template #acceptAmendAndAcceptRejectButton let-block>
    <button (click)="acceptOrReject('accept')" type="submit" class="btn btn-success">Accept</button>
    <button (click)="acceptOrReject('amend-accept')" type="submit" class="btn btn-primary ml-3">{{ acceptAndAmendButtonText }}</button>
    <button (click)="acceptOrReject('reject')" type="submit" class="btn btn-danger ml-3">Reject</button>
</ng-template>

<!-- Button Template -->
<ng-template #saveButton let-block>
    <button [disabled]="block.dataKeys ? enableButton(block.dataKeys) : ''" (click)="submitForm(block.action || 'add')" type="submit" class="btn btn-success">{{ block.label }}</button>
</ng-template>

<!-- Change Button Template -->
<ng-template #changesButton let-block>
    <button [ngClass]="block.buttonRow ? 'ml-3' : ''" [disabled]="block.dataKeys ? enableButtonOnChange(block.dataKeys, block.requiredKeys) : ''" (click)="submitForm(block.action || 'add')" type="submit" class="btn btn-success">{{ block.label }}</button>
</ng-template>

<!-- Default Button Template -->
<ng-template #defaultButton let-block>
    <button [ngClass]="block.buttonRow ? 'ml-3' : ''" (click)="defaultButtonClick(block.buttonAction)" type="submit" [class]="block.buttonClass">{{ block.label }}</button>
</ng-template>