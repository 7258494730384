import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../core/services/loader.service';
import { ILayout } from '../core/models/layout.vm';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { UsersService } from '../core/services/users.service';  
import { Users } from '../core/classes/users';
import { topLevelUserNavItems, usersNavItems, UsersTableColumns, UserTableFilters, UserTableUpdate } from './users-layout';
import { IDynamicPresetFilter } from '../core/models/dynamic-table-filter.vm';
import { Layout } from '../core/classes/layout';
import { INavItem } from '../core/models/nav-item.vm';
import { IUserRole } from '../core/models/user-role.vm';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDynamicChangeList } from '../core/models/dynamic-table-change-list.vm';
import { User } from '../core/classes/user';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  public currentUrlParams: any;
  public loadingError: string = null;
  public layout: ILayout;
  public usersTableColumns: Array<IDynamicTableColumn> = UsersTableColumns;
  public userList: Array<Users> = new Array<Users>();
  public currentUser: any = null
  public currentUserLoaded: boolean = false
  public currentUserRole: IUserRole = null;
  public presetFilters: Array<IDynamicPresetFilter> = UserTableFilters;
  public userTableUpdate: Array<IDynamicChangeList> = UserTableUpdate;
  public usersNavItems: Array<INavItem> = usersNavItems;
  public topLevelUserNavItems: Array<INavItem> = topLevelUserNavItems;
  public currentSection: string = undefined;
  public roleLayout: ILayout;
  private unsubscribe = new Subject();

  constructor(
    public loaderService: LoaderService,
    private UsersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.currentSection = params.section;
      this.currentUrlParams = params;
      this.routeChange(params);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public routeChange(params) {
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0)
    // No User selected, return to list
    if (params.section == undefined && this.userList.length == 0) {
      this.getUserList("Active", null, null);
    }
    // User selected, get details and show section
    if (params.section) {
      this.getUserDetails(this.currentUrlParams.id);
    }
  }

  private getUserList(recentlyActiveUsers?: string, adminUsers?: string, activeUsers?: string): void {
    this.currentUser = null;
    this.UsersService.getUsers(recentlyActiveUsers, adminUsers, activeUsers).pipe(takeUntil(this.unsubscribe)).subscribe(
      (success) => {
        success.data.userList.forEach((users) => {
          this.userList.push(new Users(users));
        });
        // replace all the 'null'-'undefined'-'' values with '—', in order to make the sort funcionality in the dynamic table work correctly
        this.userList.forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            let { [key as keyof typeof obj]: objKey } = obj;
            if (objKey === null || objKey === undefined || objKey === '' || objKey === ' ') {
              Object.defineProperty(obj, key, {
                value: '—',
                enumerable: true,
                writable: true
              });
            }
          });
        });
        //
        this.layout = new Layout('Users', null, null);
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public onTableRowClick = (primaryKey: any): void => {
    this.router.navigate(['users', primaryKey, "details"]);
    
  };

  public changeUsersList = (name: string): void => {
    this.userList = [];
    if(name == "Inactive") {
      this.userTableUpdate[0].hideValue = true;
      this.userTableUpdate[1].hideValue = false;
    } else {
      this.userTableUpdate[0].hideValue = false;
      this.userTableUpdate[1].hideValue = true;
    }
    this.getUserList(name, null, null);
  };

  private getUserDetails(userId): void {
    if (!this.currentUserLoaded){
      this.UsersService.getUsersDetails(userId).subscribe(
        (success) => {
          this.currentUser = new User(success.data);
          this.currentUserLoaded = !this.currentUserLoaded;
          this.layout = new Layout(
            this.currentUser.fullName,
            [ `ID: ${this.currentUser.userId}`, `Email: ${this.currentUser.userLogonName}` ],
            `/users`
            );
          },
          (error) => {
            console.log('Error: ', error);
            this.loadingError = error.error.error.message;
          }
          );
        }
  }
  
}

