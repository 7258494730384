import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicFormRules } from "src/app/core/models/dynamic-form-rules.vm";

export const ProjectDetailBasics: IDynamicFormBlock[] = [
    {
        label: 'Project Basics',
        helpText: 'The section covers the basic details of the project.',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Project ID',
        dataKey: 'projectId',
        blockType: 'input',
        inputType: 'number',
        colWidth: 12,
        hidden: true
    },
    {
        label: 'Project Name',
        dataKey: 'projectName',
        blockType: 'input',
        inputType: 'text',
        helpText: 'Please check with a senior manager before changing the project name',
        colWidth: 8
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Visibility',
        dataKey: 'isVisible',
        dataKeyRaw: 'isVisibleRaw',
        blockType: 'select',
        options: [
            { id: 'Y', name: 'Visible' },
            { id: 'A', name: 'Historical Project' },
            { id: 'N', name: 'Hidden' },
        ],
        colWidth: 4
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Project Code',
        dataKey: 'projectCode',
        blockType: 'input',
        inputType: 'text',
        helpText: 'Select two letters to prefix outputs for this project',
        colWidth: 4
    },
    {
        label: 'Project URL',
        dataKey: 'projectUrl',
        blockType: 'input',
        inputType: 'text',
        colWidth: 4,
        helpText: "This is not currently used in the Members' Area",
        disabled: true
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Product',
        dataKey: 'productId',
        blockType: 'select',
        colWidth: 4,
        options: Constants.products
    },
    {
        label: 'Category',
        dataKey: 'categoryId',
        blockType: 'select',
        colWidth: 4,
        options: Constants.categories
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Team Phone Number',
        dataKey: 'projectPhoneNo',
        blockType: 'input',
        inputType: 'text',
        colWidth: 4
    },
    {
        label: 'Project Description',
        dataKey: 'projectDescription',
        blockType: 'textarea',
        helpText: 'This description text appears on the project details page',
        colWidth: 12,
        disabled: true
    },
    {
        blockType: 'divider'
    },
    {
        label: 'Project Status',
        helpText: 'Set the overall registration and data collection status here. If data collection will always be offline, set <b>Data Collection Online</b> to "No". You can control the visibility of the current year`s data collection under <b>Dates</b>. Set <b>Outputs Online</b> to "No" if outputs are not yet available for the project.',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Registration Available',
        dataKey: 'registrationAvailable',
        dataKeyRaw: 'registrationAvailableRaw',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Organisation Questions',
        dataKey: 'hasOrganisationQuestions',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Data Collection Online',
        dataKey: 'dataCollectionOnline',
        dataKeyRaw: 'dataCollectionOnlineRaw',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        label: 'Data Collection Open',
        dataKey: 'dataCollectionOpen',
        dataKeyRaw: 'dataCollectionOpenRaw',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Outputs Online',
        dataKey: 'outputsOnline',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        label: 'Outputs Available',
        dataKey: 'outputsAvailable',
        dataKeyRaw: 'outputsAvailableRaw',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        blockType: 'divider'
    },
    {
        label: 'Project Access',
        helpText: 'The following fields set the access requirements for the project.',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Commissioner/Provider',
        dataKey: 'commissionerProvider',
        blockType: 'select',
        helpText: 'Sets organisation level access',
        options: [
            { id: 'P', name: 'Provider' },
            { id: 'C', name: 'Commissioner' },
            { id: 'N', name: 'Neither' },
        ],
        colWidth: 4
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Individual Access',
        dataKey: 'individualAccess',
        dataKeyRaw: 'individualAccessRaw',
        blockType: 'select',
        helpText: 'Sets individual level access',
        colWidth: 4,
        options: [{ id: 'Y', name: 'Yes' },{ id: 'N', name: 'No' }]
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Access Requires Role',
        dataKey: 'accessRequiresRole',
        dataKeyRaw: 'accessRequiresRoleRaw',
        blockType: 'select',
        helpText: 'Sets role level access',
        colWidth: 4,
        options: [{ id: 'Y', name: 'Yes' },{ id: 'N', name: 'No' }]
    }
];

export const ProjectDetailRules: IDynamicFormRules = {
    projectRoles: {
        min: 1,
        max: 3,
        dataKey: 'projectRoles',
        optionsType: 'fullName'        
    },
    serviceItems: {
        min: 2,
        max: -1,
        dataKey: 'serviceItems',
        optionsType: 'serviceItemId'
    },
    guidance: {
        min: 0,
        max: 0,
        dataKey: 'guidance',
        optionsType: 'projectGuidanceTypeSection'
    },
    surveys: {
        min: -1,
        max: 0,
        dataKey: 'surveys',
        optionsType: 'surveyName'
    },
    dates: {
        min: -1,
        max: 1,
        dataKey: 'projectDates',
        optionsType: 'projectDateItems'
    }
};

export const ProjectDetailRoles: IDynamicFormBlock[] = [
    {
        label: 'Project Roles',
        helpText: 'Add up to three project roles. Set one to be the <b>Principle Contact</b>: on pages where only one contact is shown, it will be the principle contact. All contacts are shown on the project details page, but just the principle contact is shown through registration and data collection.',
        blockType: 'header',
        colWidth: 12
    },
    {
        dataKey: 'projectRoles',
        blockType: 'array',
        colWidth: 12,
        children: [
            {
                label: 'Name',
                dataKey: 'userId',
                blockType: 'searchDropdown',              
                colWidth: 4,
                optionsType: 'adminUsers'
            },
            {
                label: 'Role',
                dataKey: 'projectRoleNameId',
                blockType: 'select',
                colWidth: 4,
                options: Constants.projectRoles,
            },
            {
                label: 'Principal Contact',
                dataKey: 'principleContact',
                blockType: 'select',
                colWidth: 3,
                options: Constants.yesNo,
            },
            {
                label: 'the role for',
                dataKey: 'Role',
                blockType: 'delete',
                colWidth: 1,
                optionsType: 'fullName'
            },
            {
                label: 'Project Role ID',
                dataKey: 'projectRoleId',
                blockType: 'input',
                colWidth: 3,
                hidden: true
            },
        ]
    }
];

export const ProjectDetailDates: IDynamicFormBlock[] = [
    {
        label: 'Project Dates',
        helpText: 'Dates for data collection can be set annually. Availability of registration is controlled by <b>Registration Available</b> in <b>Basics</b>. You can control the visibility of data collection (visible to all, or just within Active Admin) using <b>Data Collection Available</b> below.',
        blockType: 'header',
        colWidth: 12
    },
    {
        dataKey: 'projectDates',
        blockType: 'array',
        colWidth: 12,
        children: [
            {
                label: 'Open Collection',
                dataKey: 'openDate',
                blockType: 'input',
                inputType: 'datetime-local',            
                colWidth: 4
            },
            {
                label: 'Months Only',
                dataKey: 'openDateMonthsOnly',
                blockType: 'select',
                options: Constants.yesNo,           
                colWidth: 3
            },
            {
                blockType: 'breaker'
            },
            {
                label: 'Close Collection',
                dataKey: 'closeDate',
                blockType: 'input',
                inputType: 'datetime-local',            
                colWidth: 4
            },
            {
                label: 'Months Only',
                dataKey: 'closeDateMonthsOnly',
                blockType: 'select',
                options: Constants.yesNo,           
                colWidth: 3
            },
            {
                blockType: 'breaker'
            },
            {
                label: 'Read-only Date',
                dataKey: 'readOnlyDate',
                blockType: 'input',
                inputType: 'datetime-local',            
                colWidth: 4
            },
            {
                blockType: 'breaker'
            },
            {
                label: 'Publication',
                dataKey: 'publicationDate',
                blockType: 'input',
                inputType: 'datetime-local',            
                colWidth: 4
            },
            {
                label: 'Months Only',
                dataKey: 'publicationDateMonthsOnly',
                blockType: 'select',
                options: Constants.yesNo,           
                colWidth: 3
            },
            {
                blockType: 'breaker'
            },
            {
                label: 'Event',
                dataKey: 'eventDate',
                blockType: 'input',
                inputType: 'datetime-local',            
                colWidth: 4
            },
            {
                label: 'Months Only',
                dataKey: 'eventDateMonthsOnly',
                blockType: 'select',
                options: Constants.yesNo,           
                colWidth: 3
            },
        ]
    }
]

export const ProjectDetailServiceItems: IDynamicFormBlock[] = [
    {
        label: 'Project Services',
        helpText: 'The project services (also known as <i>service items</i>) are defined here.',
        blockType: 'header',
        colWidth: 12
    },
    {
        dataKey: 'serviceItems',
        blockType: 'array',
        colWidth: 12,
        children: [
            {
                label: 'Service ID',
                dataKey: 'serviceItemId',
                blockType: 'input',        
                colWidth: 2,
                inputType: 'number',            
                disabled: true,
            },
            {
                label: 'Service Name',
                dataKey: 'serviceItemName',
                blockType: 'input',
                inputType: 'text',            
                colWidth: 6
            },
            {
                label: 'Visible',
                dataKey: 'isVisible',
                blockType: 'select',
                colWidth: 2,
                options: Constants.yesNo,
            },
            {
                label: 'Order',
                dataKey: 'displaySequence',
                blockType: 'input',
                inputType: 'number',            
                colWidth: 2
            }        
        ]
    }
];

export const ProjectDetailGuidance: IDynamicFormBlock[] = [
    {
        label: 'Project Guidance',
        helpText: 'The project guidance is defined here. Currently, you can only update any existing guidance text for the project.',
        blockType: 'header',
        colWidth: 12
    },
    {
        dataKey: 'guidance',
        blockType: 'array',
        colWidth: 12,
        children: [
            {
                label: 'Area',
                dataKey: 'projectGuidanceTypeArea',
                helpText: 'The Members` Area page on which the guidance shows',
                blockType: 'input',
                inputType: 'text',           
                colWidth: 6,
                disabled: true
            },
            {
                label: 'Section',
                dataKey: 'projectGuidanceTypeSection',
                helpText: 'The section of the page under which the guidance shows',
                blockType: 'input',
                inputType: 'text', 
                colWidth: 5,
                disabled: true,
            },
            {
                label: 'Guidance Type ID',
                dataKey: 'projectGuidanceTypeId',
                helpText: 'The guidance type Id',
                blockType: 'input',
                inputType: 'text',
                hidden: true,
                colWidth: 1
            },
            {
                label: 'Guidance Text',
                dataKey: 'projectGuidanceText',
                helpText: 'The guidance text itself',
                blockType: 'textarea',
                colWidth: 11,
                placeholder: true
            },
            {
                label: 'guidance',
                dataKey: 'Guidance',
                blockType: 'delete',
                colWidth: 1,
                optionsType: 'projectGuidanceTypeSection'
            },
            {
                label: 'Project Guidance ID',
                dataKey: 'projectGuidanceId',
                blockType: '',
                hidden: true,
            },

        ]
    }
];


export const ProjectDetailSurvey: IDynamicFormBlock[] = [
  {
    label: 'External Surveys',
    helpText: 'These are all the external surveys linked to this project.',
    blockType: 'header',
    colWidth: 12,
  },
  {
    dataKey: 'surveys',
    blockType: 'array',
    colWidth: 12,
    children: [
      {
        label: 'Survey Name',
        dataKey: 'surveyName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 11,
      },
      {
        label: 'Open Date',
        dataKey: 'surveyOpenDate',
        blockType: 'input',
        inputType: 'datetime-local',
        colWidth: 4,
      },
      {
        label: 'Close Date',
        dataKey: 'surveyCloseDate',
        blockType: 'input',
        inputType: 'datetime-local',
        colWidth: 4,
      },
      {
        label: 'Max Responses',
        dataKey: 'maxResponses',
        blockType: 'input',
        colWidth: 3,
      },
      {
        label: 'Short Introduction',
        helpText: 'Shows on the home page of the external survey site',
        dataKey: 'surveyIntroShort',
        blockType: 'textarea',
        colWidth: 11,
      },
      {
        label: 'Full Introduction',
        helpText: 'Shows just before the user gets to the survey',
        dataKey: 'surveyIntro',
        blockType: 'textarea',
        colWidth: 11,
      },
      {
        label: 'survey',
        dataKey: 'Survey',
        blockType: 'delete',
        colWidth: 1,
        optionsType: 'surveyName',
      },
      {
        label: 'survey',
        dataKey: 'surveyId',
        blockType: '',
        colWidth: 1,
        hidden: true,
      },
      {
        label: 'projectId',
        dataKey: 'projectId',
        blockType: '',
        colWidth: 1,
        hidden: true,
      },
      {
        label: 'groupLevelId',
        dataKey: 'groupLevelId',
        blockType: '',
        colWidth: 1,
        hidden: true,
      },
      {
        label: 'isVisible',
        dataKey: 'isVisible',
        blockType: '',
        colWidth: 1,
        hidden: true,
      },
      {
        label: 'surveyIntroShortSettingId',
        dataKey: 'surveyIntroShortSettingId',
        blockType: '',
        colWidth: 1,
        hidden: true,
    },
    {
        label: 'surveyIntroSettingId',
        dataKey: 'surveyIntroSettingId',
        blockType: '',
        colWidth: 1,
        hidden: true,
    },
    {
        label: 'maxResponsesSettingId',
        dataKey: 'maxResponsesSettingId',
        blockType: '',
        colWidth: 1,
        hidden: true,
    }
    ],
  },
];

export const TranslatedProjectDetailSurvey: IDynamicFormBlock[] = [
    {
      label: 'External Surveys',
      helpText: 'These are all the external surveys linked to this project.',
      blockType: 'header',
      colWidth: 12,
    },
    {
      dataKey: 'surveys',
      blockType: 'array',
      colWidth: 12,
      children: [
        {
          label: 'Survey Name',
          dataKey: 'surveyName',
          blockType: 'input',
          inputType: 'text',
          colWidth: 11,
        },
        {
          label: 'Open Date',
          dataKey: 'surveyOpenDate',
          blockType: 'input',
          inputType: 'datetime-local',
          colWidth: 4,
        },
        {
          label: 'Close Date',
          dataKey: 'surveyCloseDate',
          blockType: 'input',
          inputType: 'datetime-local',
          colWidth: 4,
        },
        {
          label: 'Max Responses',
          dataKey: 'maxResponses',
          blockType: 'input',
          colWidth: 3,
        },
        {
          label: 'Short Introduction',
          helpText: 'Shows on the home page of the external survey site',
          dataKey: 'surveyIntroShort',
          blockType: 'textarea',
          colWidth: 11,
        },
        {
          label: 'Full Introduction',
          helpText: 'Shows just before the user gets to the survey',
          dataKey: 'surveyIntro',
          blockType: 'textarea',
          colWidth: 11,
        },
        {
          label: 'Translated Short Introduction',
          helpText: 'Shows on the home page of the external survey site',
          dataKey: 'translatedSurveyIntroShort',
          blockType: 'textarea',
          colWidth: 11,
        },
        {
          label: 'Translated Full Introduction',
          helpText: 'Shows just before the user gets to the survey',
          dataKey: 'translatedSurveyIntro',
          blockType: 'textarea',
          colWidth: 11,
        },
        {
          label: 'survey',
          dataKey: 'Survey',
          blockType: 'delete',
          colWidth: 1,
          optionsType: 'surveyName',
        },
        {
          label: 'survey',
          dataKey: 'surveyId',
          blockType: '',
          colWidth: 1,
          hidden: true,
        },
        {
          label: 'projectId',
          dataKey: 'projectId',
          blockType: '',
          colWidth: 1,
          hidden: true,
        },
        {
          label: 'groupLevelId',
          dataKey: 'groupLevelId',
          blockType: '',
          colWidth: 1,
          hidden: true,
        },
        {
          label: 'isVisible',
          dataKey: 'isVisible',
          blockType: '',
          colWidth: 1,
          hidden: true,
        },
      ],
    },
  ];

export const projectDetailsPeerGroupBlocks: IDynamicFormBlock[] = [
    {
        label: 'Peer Groups',
        helpText: 'These are all the defined peer groups for this project.',
        blockType: 'header',
        colWidth: 12
    },
    {
        dataKey: 'peerGroups',
        blockType: 'array',
        colWidth: 12,
        children: [
            {
                label: 'Type',
                dataKey: 'peerGroupTypeName',
                blockType: 'input',
                inputType: 'text',           
                colWidth: 3,
                disabled: true
            },
            {
                label: 'Group Name',
                dataKey: 'peerGroupName',
                blockType: 'input',
                inputType: 'text',           
                colWidth: 6
            },
            {
                label: 'Order',
                dataKey: 'displaySequence',
                blockType: 'input',
                inputType: 'number',           
                colWidth: 3
            },
        ]
    }
];