<div [formGroup]="form" [class.d-none]="block.hidden" *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div class="form-group">
        <label [for]="block.dataKey" [ngClass]="{'text-light': ui.darkMode}">{{ block.label }}</label>
        <small [id]="block.dataKey + 'Help'" class="form-text text-muted" [innerHTML]="block.helpText"></small>
        <input
            [type]="block.inputType"
            class="form-control"
            [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}"
            [id]="block.dataKey"
            [formControlName]="block.dataKey"
            [attr.aria-describedby]="block.dataKey + 'Help'">
        <app-input-placeholder *ngIf="block.placeholder"></app-input-placeholder>
        <app-input-copy *ngIf="block.copyToClip" [block]="block"></app-input-copy>
    </div>
</div>