import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Organisation } from 'src/app/core/classes/organisation';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { OrganisationDetailNavItems } from '../organisation-layouts';
import { OrganisationDetailBasics } from './organisation-detail-layouts';
import { IOrganisation } from 'src/app/core/models/organisation.vm';
import { IOrganisationDetails } from 'src/app/core/models/organisation-details.vm';

@Component({
  selector: 'app-organisation-detail',
  templateUrl: './organisation-detail.component.html',
  styleUrls: ['./organisation-detail.component.scss'],
})
export class OrganisationDetailComponent implements OnChanges {
  @Input() public currentOrganisation: Organisation;

  public currentQueryParams: any;
  public currentScope: string = null;
  public loadingError: string = null;
  processLoading: boolean = false;

  public organisationDetailBasicsBlocks: Array<IDynamicFormBlock> = OrganisationDetailBasics;
  public organisationDetailNavItems: Array<INavItem> = OrganisationDetailNavItems;

  public alert: Object;

  constructor(
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    private organisationService: OrganisationService
  ) {
    this.getRegion();
    this.getOrganisationType();
  }

  ngOnChanges() {
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
      this.scopeChange(this.currentQueryParams, this.currentOrganisation);
    });
  }

  private scopeChange(params: any, currentOrganisation: Organisation): void {
    this.currentScope = params.scope || 'basics';
  }

  getOrganisationType(): void {
    this.organisationService.getOrganisationTypes().subscribe(
      (response: any) => {
        if (!response.success) {
          this.loadingError = "An error occured while fetching organisation type.";
          return;
        }
        this.updateOrganisationTypeOptions(response.organisationTypes);
      },
      (error: any) => {
        this.loadingError = error.error.error.message;
      }
    )
  }

  updateOrganisationTypeOptions(types: Array<{id: number, name: string}>): void {
    types = types.map((item: any) => ({
      id: item.organisation_type_id,
      name: item.organisation_type_name
    }));

    this.organisationDetailBasicsBlocks = this.organisationDetailBasicsBlocks.map((item:any) => {
      if(item.dataKey != 'organisationTypeId') return item;
      return {
        ...item,
        options: types
      }
    });
  }

  getRegion(): void {
    this.organisationService.getOrganisationRegion().subscribe(
      (response: any) => {
        if (!response.success) {
          this.loadingError = "An error occured while fetching organisation region.";
          return;
        }
        this.updateRegionOptions(response.Regions);
      },
      (error: any) => {
        this.loadingError = error.error.error.message;
      }
    )
  }

  updateRegionOptions(regions: Array<any>): void {
    regions = regions.map((item: any) => ({
      id: item.region_id,
      name: item.region_name
    }));

    this.organisationDetailBasicsBlocks = this.organisationDetailBasicsBlocks.map((item:any) => {
      if(item.dataKey != 'regionId') return item;
      return {
        ...item,
        options: regions
      }
    });
  }

  updateOrganisation(event: any): void {
    if(this.processLoading) return;

    this.processLoading = true;
    const data: IOrganisationDetails = {
      organisationId: this.currentOrganisation.organisationId,
      organisationName: event.row.organisationName,
      organisationTypeId: event.row.organisationTypeId,
      organisationTypeOther: event.row.organisationTypeOther,
      regionId: event.row.regionId,
      NHSCode: event.row.nhsCode,
      isDefunct: event.row.isDefunct,
      addressLine1: event.row.postalAddress1,
      addressLine2: event.row.postalAddress2,
      addressLine3: event.row.postalAddress3,
      addressLine4: event.row.postalAddress4,
    };

    this.organisationService.updateOrganisationDetails(data).subscribe(
      (response: any) => {
        this.processLoading = false;

        if (!response.success) {
          this.alert = {
            message: 'Organisation update Failed.',
            alertClass: 'danger',
            fadeOut: true,
          };
          return;
        }

        this.alert = {
          message: 'Organisation details updated successfully.',
          alertClass: 'success',
          fadeOut: true,
        };
      },
      (error: any) => {
        this.alert = {
          message: 'Organisation update failed.',
          alertClass: 'danger',
          fadeOut: true,
        };
      }
    )
  }
}
