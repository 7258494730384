import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDynamicFormRule } from 'src/app/core/models/dynamic-form-rule.vm';
import { Router } from '@angular/router';
// import { lookup } from 'dns';
import { DynamicFormDropdownComponent } from 'src/app/shared/dynamic-form/dynamic-form-dropdown/dynamic-form-dropdown.component';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnChanges {
  @Input() public data;
  @Input() public blocks: Array<IDynamicFormBlock>;
  @Input() public rules: IDynamicFormRule;
  @Input() public tableData:any;
  @Input() public roles:any;
  @Input() public products:any;
  @Input() public parentSection?: string;
  @Output() public dataUpdate = new EventEmitter<any>();
  @Output() id = new EventEmitter<object>();
  @Output() buttonAction = new EventEmitter<object>();
  @Output() onRolesClick = new EventEmitter<any>();
  @ViewChild(DynamicFormDropdownComponent) dynamicFormDropdownComponent: DynamicFormDropdownComponent;

  public form: FormGroup;
  public setRule: boolean;
  public selectedOrganisation: string = null;

  darkMode$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private store: Store<{ darkMode: boolean }>,
    private router: Router,
  ) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnChanges(): void {  
    if(this.rules) {
      let { [this.rules.dataKey]: dataKey } = this.data;
      this.setRule = ((this.rules.max == dataKey.length && this.rules.max != -1) || this.rules.max == 0)
    }
    this.form = this.toFormGroup(this.data, this.blocks);
  }

  reloadOrganisationsList() {
    if (this.dynamicFormDropdownComponent) {
      this.dynamicFormDropdownComponent.reloadOrganisationsList()
    }
  }

  enableButton(inputs) {
    let option = inputs.every(v => (this.form.get(v).value !== '') && (this.form.get(v).value !== undefined) && (this.form.get(v).value !== null));
    return !option;
  }

  enableButtonOnChange(inputs) {
    let option = inputs.find(v => ( this.form.get(v).touched ));
    return !option;
  }

  buttonClicked(action) {
    this.buttonAction.emit(action);
  }

  removeItem(row: number) {
    this.formUpdate({row: row, action: 'remove'});
  }

  addItem(key: string) {
    let { [key]: dataKey } = this.data;
    dataKey.push({});
    this.form = this.toFormGroup(this.data, this.blocks);
  }

  formUpdate(option) {
    this.dataUpdate.emit(option);
    this.form = this.toFormGroup(this.data, this.blocks);
  }

  private toFormGroup(data: any, blocks: any): FormGroup {
    // Instantiate FormGroup
    const formGroup: FormGroup = this.fb.group({});
    // Check each block type and add controls
    blocks.forEach((block) => {
      let blockIsArray: boolean = block.dataKey && block.blockType == 'array';
      block.noData = false;
      const blockDataKey = block.dataKey;
      let { [blockDataKey]: dataBlockDataKey } = data;
      if (blockIsArray) {
        // Check for no data
        if (dataBlockDataKey.length == 0) {
          block.noData = true;
        }
        // Create array
        const formArray = this.toFormArray(data, block);
        formGroup.addControl(block.dataKey, formArray);
      } else if(block.dataKey) {
        formGroup.addControl(block.dataKey, this.toFormControl(data, block));
      }
    });
    // Return completed group
    return formGroup;
  }

  private toFormArray(data: any, block: any): FormArray {  
    // Instantiate FormArray
    const formArray: FormArray = this.fb.array([]);
    const blockDataKey = block.dataKey;
    let { [blockDataKey]: dataBlockDataKey } = data;
    dataBlockDataKey.forEach((data) => {
      const formGroup: FormGroup = this.fb.group({});
      block.children.forEach((child) => {
        if(child.blockType != 'delete'){
          formGroup.addControl(child.dataKey, this.toFormControl(data, child));
        }
      });
      formArray.push(formGroup);
    });
    // Return array back to FormGroup
    return formArray;
  }

  private toFormControl(data: any, block: any): FormControl {
    const blockDataKeyRaw = block.dataKeyRaw;
    let { [blockDataKeyRaw]: dataBlockDataKeyRaw } = data;
    const blockDataKey = block.dataKey;
    let { [blockDataKey]: dataBlockDataKey } = data;
    if(dataBlockDataKeyRaw) {
      dataBlockDataKey = dataBlockDataKeyRaw;
    }
    let value: any = dataBlockDataKey;
    
    if (block.inputType == 'datetime-local' && value) {
      value = new Date(value).toISOString().substring(0, 16);
    }
    if (block.blockType == 'select' && typeof value === 'object' && value) {
      value = value.code;
    }
    // Return form control
    return this.fb.control({ value: value, disabled: block.disabled });
  }

  optionSelected(value) {
    this.selectedOrganisation = value;
    this.dataUpdate.emit({row: this.form.getRawValue(), action: 'add', organisation: this.selectedOrganisation});
  }

  isFormComplete(): boolean {
    for (const control in this.form.controls) {
      if (this.form.controls[control].value === null || this.form.controls[control].value === '') {
        return false;
      }
    }
    return true;
  }

  submitForm(action: string) {
    this.dataUpdate.emit({row: this.form.getRawValue(), action: action, organisation: this.selectedOrganisation});
  }

  registerButtonClick() {
    this.dataUpdate.emit(this.form.getRawValue());
  }

  defaultButtonClick(action) {
    this.dataUpdate.emit(action);
  }

  acceptOrReject(event: string) {
    this.dataUpdate.emit({form: this.form.getRawValue(), action: event});
  }

  openRolesSlider() {
    this.onRolesClick.emit();
  }

  // Temporary method: to be replaced when API calls
  // are in place; potential to do auto-saving
  onSubmit() {
    // this.dataUpdate.emit({row: this.form.getRawValue(), action: 'add', organisation: this.selectedOrganisation});
  }

  idCheck(event){
    this.id.emit(event)
  }
}



