<div *ngIf="roles.length && products.length">
  <h4 class="mt-5 mb-3 text-primary">Add roles</h4>
  <form [formGroup]="form" *ngIf="{ darkMode: darkMode$ | async } as ui" (ngSubmit)="onSubmit()">
    <div class="row justify-content-start">
      <div *ngFor="let block of blocks" [class]="'col-md-' + block.colWidth + ' col-12'">
        <div class="form-group">  
          <small [id]="block.dataKey + 'Help'" class="form-text text-muted" [innerHTML]="block.helpText"></small>
          <select #mySelect class="form-control" [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}"
              [id]="block.dataKey" [formControlName]="block.dataKey" multiple>
              <ng-container *ngIf="block.options">
                <option *ngFor="let option of block.options" [ngValue]="option.id">
                    {{ option.name }}
                </option>
              </ng-container>      
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success mt-2" [disabled]="!selection.roles.length || !selection.products.length ">Add</button>
  </form>
</div>
<div *ngIf="!roles.length || !products.length" class="mt-5 text-center">
  <p>No roles available for this organisation...</p>
</div>
