import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';

@Component({
  selector: 'app-table-export-search',
  templateUrl: './table-export-search.component.html',
  styleUrls: ['./table-export-search.component.scss']
})
export class TableExportSearchComponent {

  @Input() public data: any;
  @Input() public columns: Array<IDynamicTableColumn>;
  @Input() public tableTitle: string;
  @Input() public hideSearch: boolean;
  @Input() public hideExportButton: boolean;
  @Input() public searchableColumns: Array<string>;
  @Output() public searchValue = new EventEmitter<String>();

  public searchText: string = '';
  public searchPlaceholder: string = 'Search...';

  constructor(    
    public tooltipConfig: NgbTooltipConfig
    ) {
      tooltipConfig.openDelay = 750
    }

  

  // Clears input if user has not typed
  public clearInput(clickAway: boolean): void {
    if(this.searchText == '' && clickAway) {
      this.applyInputSettings('Search...', null)
      setTimeout(()=>{ 
        this.applyInputSettings('Search...', undefined)
      }, 200);
    } else if(!clickAway) {
      this.searchText = '';
      this.applyInputSettings('Search...', undefined)
    }
  }

  // Applying the correct placeholder and emit value
  public applyInputSettings(placeholder: string, value: any) {
    this.searchPlaceholder = placeholder;
    this.searchValue.emit(value);
  }

  // Clears input or keeps it if user selects from table
  public searchInput(event: any){
    if(event == ''){
      this.applyInputSettings('Search...', undefined)
    } 
    if(event == null && this.searchText !== '') {
      this.applyInputSettings(this.searchPlaceholder, this.searchText)
    } else {
      let placeholder = 'Search highlighted columns...';
      this.applyInputSettings(placeholder, event)
    }
  }

  public downloadAsCsv(data, filename: string): void {
    var filteredData = this.columns.filter(el => { return el.exportable != false; }); 
    let arrHeader = filteredData.map(col => col.dataKey);
    let csvData = this.convertToCsv(data, arrHeader);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) { 
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${filename}.csv`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  
  private convertToCsv(data: any, arrHeader: Array<string>): string {
    //   let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    //   let str = '';
    //   let row = '';
    //   for (let index in arrHeader) {
    //     row += arrHeader[index] + ',';
    //   }
    //   row = row.slice(0, -1);
    //   str += row + '\r\n';
    //   for (let i = 0; i < array.length; i++) {
    //     let line = '';
    //     for (let index in arrHeader) {
    //       let head = arrHeader[index];

    //       line += this.strRep(array[i][head]) + ',';
    //     }    
        ////////////////////////////////////////////////////////////      FIX         //////////////////////////////////////////
    let dataArr: Array<any> = typeof data != 'object' ? JSON.parse(data) : data;

    let str = '';
    let row = '';
    row = arrHeader.join(',')
    str += row + '\r\n';

    dataArr.forEach(element => {
      let line = '';
      let whitelist = Object.keys(element);

      for (const [_key, col] of Object.entries(arrHeader)) {

        if (whitelist.indexOf(col) != -1) {
          const { [col]: value } = element
          line += this.strRep(value) + ',';
        }               
      }
       /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      str += line + '\r\n';
    });
    return str;
  }



  private strRep(data): string {
    if(typeof data == "string") {
      let newData = data.replace(/,/g, " ");
       return newData;
    }
    else if(typeof data == "undefined") {
      return "-";
    }
    else if(typeof data == "number") {
      return  data.toString();
    }
    else {
      return data;
    }
  }
}
