import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { IDynamicPresetFilter } from '../core/models/dynamic-table-filter.vm';
import { IDynamicChangeList } from '../core/models/dynamic-table-change-list.vm';
import { INavItem } from '../core/models/nav-item.vm';

export const UsersTableColumns: IDynamicTableColumn[] = [
  {
    header: 'User ID',
    dataKey: 'userId',
    percWidth: 5,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Full Name',
    dataKey: 'userFullName',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Job Title',
    dataKey: 'userJobTitle',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Email',
    dataKey: 'userEmail',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Admin',
    dataKey: 'isAdmin',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
    badge: 'yesNo'
  },
  {
    header: 'Successful Login',
    dataKey: 'successfulLogons',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Failed Login',
    dataKey: 'failedLogons',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: false,
  },
];

export const UserTableFilters: IDynamicPresetFilter[] = [
  {
      name: 'Inactive Users', 
      key: 'isActive', 
      value: 'N', 
      hideValue: true
  },
  {
    name: 'Recently Inactive', 
    key: 'recentlyActive', 
    value: 'Inactive', 
    hideValue: true
}
]

export const UserTableUpdate: IDynamicChangeList[] = [
  {
      header: 'Users',
      name: 'Active Users',
      currentList: 'Active',
      hideValue: false,
      displayHelpText: false
  },
  {
      header: 'Users',
      name: 'Inactive Users',
      currentList: 'Inactive',
      hideValue: true,
      displayHelpText: false
  }
];


export const topLevelUserNavItems: INavItem[] = [
  {
    icon: 'fa-house',
    label: 'Current Users',
    link: '/users'
  },
  {
    label: 'Pending Users',
    icon: 'fa-tasks',
    link: '/users-pending',
    },
];

export const usersNavItems: INavItem[] = [
  {
    icon: 'fa-house',
    label: 'Details',
    link: '../details'
  },
  {
    label: 'Roles',
    icon: 'fa-tasks',
    link: '../roles',
    },
  {
  label: 'Login Attempts',
  icon: 'fa-tasks',
  link: '../login-attempts',
  },
  {
    label: 'Activity',
    icon: 'fa-tasks',
    link: '../activity',
  },
];

export const usersDetailNavItems: INavItem[] = [
  {
    label: 'Basics',
    link: '../details',
    scope: 'basics',
  },
];