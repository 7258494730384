import { IDynamicTableColumn } from "../core/models/dynamic-table-column.vm";
import { INavItem } from "../core/models/nav-item.vm";

export const OrganisationNavItems: INavItem[] = [
    {
        icon: 'fa-house',
        label: 'Details',
        link: '../details'
    },
    {
        icon: 'fa-box',
        label: 'Products',
        link: '../products'
    },
    {
        label: 'Registrations',
        icon: 'fa-tasks',
        link: '../registrations',
    },
    {
        icon: 'fa-map-marker-alt',
        label: 'Submissions',
        link: '../submissions',
    },
    {
        icon: 'fa-users',
        label: 'Users',
        link: '../users',
    },
    {
        icon: 'fa-tasks-alt',
        label: 'MOU',
        link: '../mou',
    },
    {
        icon: 'fa-user-check',
        label: 'Project Access',
        link: '../project-access',
        disabled: true
    },
    {
        icon: 'fa-comment',
        label: 'Communications',
        link: '../communications',
        disabled: true
    },
    {
        icon: 'fa-file-alt',
        label: 'Documents',
        link: '../documents',
        disabled: true
    },
];

export const OrganisationTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'organisationId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 30,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Organisation Type',
        percWidth: 30,
        dataKey: 'organisationTypeName',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Data sharing consent',
        percWidth: 15,
        dataKey: 'dataSharingConsent',
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: 'Defunct',
        dataKey: 'isDefunct',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: '# Products',
        dataKey: 'productsCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'MOU',
        dataKey: 'mouCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Region',
        percWidth: 10,
        dataKey: 'regionName',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'NHS Code',
        percWidth: 10,
        dataKey: 'nhsCode',
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Chart Views',
        percWidth: 10,
        dataKey: 'reportViews',
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Document Downloads',
        percWidth: 10,
        dataKey: 'documentViews',
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Products',
        percWidth: 10,
        dataKey: 'productsString',
        sortable: true,
        groupable: false,
        searchable: false
    }
];

export const OrganisationDetailNavItems: INavItem[] = [
    {
        label: 'Basics',
        link: '../details',
        scope: 'basics'
    }
];