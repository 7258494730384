 <div class="d-flex justify-content-center">
    <div>
        <div  class="file-upload">
            <div class="d-flex justify-content-center">
            </div>
                <div appDragAndDrop (dropped)="onDrop($event)"
                class="drag-area">
                <input type='file' name="file" id="file" (change)="onDrop($event.target.files)" multiple hidden />
                <div class="icon">
                    <i class="fas fa-file-upload fa-2x"></i>                
                </div>
                <span class="header">Drag & Drop</span>
                <label for="file">
                    <span class="header">or <span class="button">browse</span></span> 
                </label>
                </div>
        </div>
        <div *ngIf="files.length > 0 && callerName !== 'dynamicDocument'">
            <button (click)="uploadDocument()" style="margin-top: 10px;" class="btn btn-success">Save</button>
        </div>
    </div>
<!-- List of Files -->
 <div *ngIf="files.length > 0">
    <div class="fileHolder">
     <div class="fileItem" *ngFor="let file of files; let ind = index">
         <div >
                 <i class="fas fa-file-alt fa-2x"></i>		
             <span class="ml-2 fileName">{{file.name}}</span>
             <div class="close">
                 <i (click)="untickFile(file)" class="far fa-lg fa-times"></i>
             </div>
            </div>
        </div>
    </div>
</div>
</div>