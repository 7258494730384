<!-- Loading and error -->
<app-loading-status *ngIf="(loaderService.isLoading | async) && !project; else notLoading"></app-loading-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<!-- Tooltip -->
<ng-template #tooltipContent>
  <span>Toolkit not available</span>
</ng-template>

<ng-template #notLoading>
  <div class="col-12">
    <button type="button" class="btn mb-3 mr-3" [class.btn-primary]="showListView" [class.btn-secondary]="!showListView"
      (click)="switchView(true)">List view</button>
    <button type="button" [disabled]="!project.tiers.length" [ngbTooltip]="!project.tiers.length ? tooltipContent : ''"
      class="btn mb-3" [class.btn-primary]="!showListView" [class.btn-secondary]="showListView"
      (click)="switchView(false)">Tier view</button>
  </div>
  <div class="container-fluid" *ngIf="showListView">
    <!-- Outlier Identifier -->
    <ng-template #outlierTooltip> Find outliers for current project and year</ng-template>

    <!-- Comment back in when Outlier-Identifier is ready -->
    <!-- <button 
        class="btn btn-outline-primary mb-4" 
        [ngbTooltip]="outlierTooltip" 
        (click)="triggerOutlierIdentifier(currentProject.projectId, currentYear)"
        [disabled]="outlierIdentifierRunning">
            <i class="far fa-search-plus mr-2"></i>Find Outliers
    </button> -->

    <!-- Table -->
    <app-dynamic-table *ngIf="project.validations" 
      [data]="project.validations" 
      [columns]="validationTableColumns"
      [currentYear]="currentYear" 
      [tableTitle]="'validationList'" 
      [primaryKey]="'validationId'"
      [onTdClick]="onTableTdClick" 
      [disableRowClick]="true">
    </app-dynamic-table>
  </div>

  <!-- TIER VIEW -->
  <div class="container-fluid" *ngIf="project?.validations && !showListView">
    <!-- Content -->
    <div class="row" *ngIf="{ darkMode: darkMode$ | async } as ui">
      <div class="col-md-4">
        <span class="text-muted">Chart types that cannot be validated have been disabled.</span>
        <ng-container *ngIf="project?.tiers.length && project?.validations">
          <!-- Tier tree -->
          <div class="sidebar" [ngClass]="{'bg-dark-900 text-light border-dark-700': ui.darkMode}">
            <!-- Search and results -->
            <div class="position-relative">
              <input [ngClass]="{'bg-dark-900 text-light border-dark-700': ui.darkMode}" type="text"
                class="form-control mb-2" [(ngModel)]="tierSearch" placeholder="Search by tier ID or name...">
              <div class="mt-2 text-muted" *ngIf="(searchableTiers | filterBy: ['id', 'name']: tierSearch).length == 0">
                There are no tiers matching the search.
              </div>
              <div class="tier-search-results" *ngIf="tierSearch">
                <div *ngFor="let tier of searchableTiers | filterBy: ['id', 'name']: tierSearch"
                  (click)="getSearchTier(tier, project.tiers)" class="mb-2 c-pointer searchable-tier">
                  {{ tier.name }}
                  <small *ngIf="tier.serviceItemName" class="text-muted"><br>{{ tier.serviceItemName }}</small>
                </div>
              </div>
            </div>
            <!-- Flat tree -->
            <ng-container *ngIf="!tierSearch">
              <div *ngFor="let tier of project.tiers"
                [style]="allowedChartTypes(tier.defaultChartType) ? '' : 'pointer-events:none; color: grey'"
                [class]="selectedTier?.id == tier.id ? 'tier active' : 'tier'"
                (click)="tier.reportId ? getTier(tier, 0) : toggleTierCollapsed(tier)">
                <ng-container *ngIf="!tier.collapsed">
                  <div [id]="tier.id" [innerHtml]="tier.displayName"></div>
                  <span *ngIf="tier.pendingValidationsCount" class="badge badge-success ml-2">{{
                    tier.pendingValidationsCount }}</span>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="col-md-8">
        <ng-container *ngIf="selectedTier">
          <!-- Heading -->
          <h3 style="margin-top:0" [ngClass]="{'text-light': ui.darkMode}">{{ selectedTier.report.reportName }}</h3>
          <small [ngClass]="{'text-light': ui.darkMode}">
            Reference (links to outputs): <a
              [href]="'https://members.nhsbenchmarking.nhs.uk/outputs/' + currentProject.projectId + '?tier=' + selectedTier.id"
              target="_blank">{{ selectedTier.id }}-{{ selectedTier.reportId }}</a> | Type: {{
            selectedTier.report.viewTypes[0].viewType }}
          </small>
          <hr>
          <ng-container *ngIf="!errorMessage">
            <!-- Option -->
            <div class="row form-group">
              <div class="col-md-4">
                <label for="option" [ngClass]="{'text-light': ui.darkMode}" style="width:100%">
                  <span>Options</span>
                  <ng-container *ngIf="selectedTier.report.options.denominators.length">
                    <div class="option-shifters">
                      <i class="fas fa-caret-circle-left text-muted" (click)="shiftOption('down')" ngbTooltip="Previous option"></i>
                      <i class="fas fa-caret-circle-right text-muted" (click)="shiftOption('up')" ngbTooltip="Next option"></i>
                    </div>
                  </ng-container>             
                </label>
                <select name="option" id="option" [(ngModel)]="selectedOptionId"
                  (change)="getTier(selectedTier, $event.target.value, selectedPeerGroupId)" class="form-control"
                  [disabled]="selectedTier.report.options.denominators.length < 2"
                  [ngClass]="{'bg-dark-900 text-light border-dark-700': ui.darkMode}">
                  <option [value]="null" selected disabled>Select an option...</option>
                  <option *ngFor="let opt of selectedTier.report.options.denominators" [value]="opt.optionId">{{opt.optionName}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="peer" [ngClass]="{'text-light': ui.darkMode}">Peer Group</label>
                <select name="peer" id="peer" [ngModel]="selectedPeerGroupId"
                  (change)="getTier(selectedTier, selectedOptionId, $event.target.value)" class="form-control"
                  [disabled]="selectedTier.report.peerGroups.length < 2"
                  [ngClass]="{'bg-dark-900 text-light border-dark-700': ui.darkMode}">
                  <option [value]="null" selected disabled>Select a peer group...</option>
                  <option *ngFor="let opt of selectedTier.report.peerGroups" [value]="opt.id">{{ opt.name }}</option>
                </select>
              </div>
            </div>

            <!-- Chart -->
            <div *ngIf="noData" class="pb-5 pt-5" style="text-align: center">
              <h2 [ngClass]="{'text-light': ui.darkMode}">No data to display</h2>
            </div>
            <app-highchart *ngIf="!noData && chartObject"
              [chartObject]="ui.darkMode ? highchartsColourMode('dark') : highchartsColourMode('light')"
              [chartClick]="chartClick"></app-highchart>

            <!-- Averages -->
            <div *ngIf="!noData" [ngClass]="{'text-light': ui.darkMode}">
              <span class="mr-2">
                <i class="fas fa-horizontal-rule mr-2" style="color:#009639"></i>Mean: {{ mean | number: '1.2-2' }}
              </span>
              <span class="mr-2">
                <i class="fas fa-horizontal-rule mr-2" style="color:#78BE20"></i>Median: {{ median | number: '1.2-2' }}
              </span>
            </div>
            <hr>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Table -->
  <div *ngIf="selectedTier?.validations.length > 0 && { darkMode: darkMode$ | async } as ui">
    <h4 [ngClass]="{'text-light': ui.darkMode}" style="font-weight: bold">Validation Points</h4>
    <app-dynamic-table 
      [data]="selectedTier?.validations" 
      [columns]="validationTableColumns" 
      [currentYear]="currentYear"
      [tableTitle]="'validationList'" 
      [hideExportButton]="true" 
      [hideSearch]="true" 
      [primaryKey]="'validationId'"
      [onTdClick]="onTableTdClick" 
      [disableRowClick]="true">
    </app-dynamic-table>
  </div>
</ng-template>

<!-- Selected Validation -->
<app-slider-panel *ngIf="validationSelection && validationLayout" [onClose]="closeValidationSlider">

  <!-- Heading -->
  <app-area-heading [heading]="validationLayout.heading" [subheading]="validationLayout.subheading"></app-area-heading>
  <div *ngIf="availableResponses?.length > 0">
    <hr />
    <div *ngIf="{ darkMode: darkMode$ | async } as ui">
      <p [ngClass]="{'text-light': ui.darkMode}"><b>Link validation point to a response </b><br />
        Select a response from the parameters below.</p>
    </div>
    <!-- Table -->
    <app-dynamic-table 
      [data]="availableResponses" 
      [columns]="validationSliderTableColumns" 
      [currentYear]="currentYear"
      [tableTitle]="'validationList'" 
      [primaryKey]="'validationId'" 
      [hideExportButton]="true" 
      [disableRowClick]="true"
      [hideSearch]="true" 
      [onTdClick]="onTableTdClick">
    </app-dynamic-table>
  </div>

  <!-- Validation Details -->
  <app-dynamic-form [ngStyle]="{'pointer-events': responseSet ? '' : 'none'}" [data]="validationSelection"
    [blocks]="ValidationDetailsForm" (dataUpdate)="noteUpdate($event)"></app-dynamic-form>

  <div [ngStyle]="{'pointer-events': responseSet ? '' : 'none'}" class="codeList requires-no-scroll" role="dialog">
    <div style="margin-top: 5em; margin-bottom: 10em;">
      <h3>Validation chat</h3>
      <div class="card" style="background-color: #efefef;">
        <div class="chat">
          <div class="chat-header clearfix">
            <div style="text-align: center;">
              <b>{{ validationSelection.submissionName }}</b> <br />
              <small>Q: {{ validationSelection?.displayQuestionText }}</small>
            </div>
          </div>
          <div class="chat-history"
            style="height: 30em; overflow-x: auto; display: flex; flex-direction: column-reverse;">
            <ul class="m-b-0">
              <li *ngFor="let data of chatHistory" class="clearfix">
                <div *ngIf="data.message">
                  <div class="message-data" [class.text-right]="data.is_user == 'N'">
                    <img *ngIf="data.is_user == 'Y'" src="../../../assets/userIcon.png" alt="avatar">
                    <span class="message-data-time">{{ data.sent_at | date: 'MMM d, y h:mm a' }}</span>
                  </div>
                  <div class="message" [ngClass]="data.is_user == 'N' ? 'other-message' : 'my-message'"
                    [class.float-right]="data.is_user == 'N'">
                    {{ data.message }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="chat-message clearfix">
            <div class="mb-0" style="display: flex;">
              <input type="text" class="form-control search-input" (input)="checkWhitespace($event)" maxlength="500" [(ngModel)]="comment"
                placeholder="Enter message for user...">
              <button [disabled]="!comment" class="btn btn-success" style="margin-left: 5px;"
                (click)="editValidationDescription(validationSelection, comment, 'N'); comment = null">Send</button>
              <button [disabled]="!comment" class="btn btn-warning" style="margin-left: 5px;"
                (click)="editValidationDescription(validationSelection, comment, 'Y'); comment = null">Send(Alert)</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-slider-panel>

<app-popup-modal *ngIf="modalInfo" [modalInfo]="modalInfo" (action)="closeModal($event)"></app-popup-modal>