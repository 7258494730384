import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Layout } from 'src/app/core/classes/layout';

@Component({
  selector: 'app-drag-and-drop-uploader',
  templateUrl: './drag-and-drop-uploader.component.html',
  styleUrls: ['./drag-and-drop-uploader.component.scss']
})
export class DragAndDropUploaderComponent implements OnInit {
  @Output() uploadFile = new EventEmitter<any>();
  @Input() callerName: string;
  public newDocumentLayout: Layout;

  public files: any[] = [];
  public dynamicDocument: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.callerName === 'dynamicDocument') {
      this.dynamicDocument = true
    }
  }

  public newDocumentClick() {
    this.newDocumentLayout = new Layout(
      'New Document Upload',
      ['Please upload a document file'],
      null
    )
  }

  public onDrop(event){
    for (const item of event) {
      this.files.push(item);
    }
    if(this.dynamicDocument == true){
      this.uploadFile.emit(this.files)
    }
  }

  onFileChange(file){
    this.files.push(Object.keys(file).map(key => file[key]))
  }

  public untickFile(file){
    if(this.files.find(item => item == file )){
     this.files = this.files.filter(item => item != file)
    }
    if(this.dynamicDocument == true){
      this.uploadFile.emit(this.files)
    }
  }

  public uploadDocument(){
    this.uploadFile.emit(this.files)
  }

}
