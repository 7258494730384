import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Users } from 'src/app/core/classes/users';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { topLevelUserNavItems } from '../users-layout';
import { Layout } from 'src/app/core/classes/layout';
import { UserPendingForm, UsersTableColumns } from './users-pending-layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { UsersService } from 'src/app/core/services/users.service';
import { IPendingUser } from 'src/app/core/models/user-pending.vm';
import { IUserPendingPost } from 'src/app/core/models/user-pending-post.vm';
import { IUserPendingForm } from 'src/app/core/models/user-pending-form.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-users-pending',
  templateUrl: './users-pending.component.html',
  styleUrls: ['./users-pending.component.scss']
})
export class UsersPendingComponent implements OnInit {

  public loadingError: string = null;
  public layout: ILayout;
  public userList: Array<Users> = new Array<Users>();
  public currentUser: any = null
  public usersTableColumns: Array<IDynamicTableColumn> = UsersTableColumns;
  public UserPendingForm: Array<IDynamicFormBlock> = UserPendingForm;
  public topLevelUserNavItems: Array<INavItem> = topLevelUserNavItems;
  public currentPendingUser: any;
  public currentQueryParams: any;
  public detailslayout: ILayout;
  public modalInfo: Object = null; 
  public commentValue: string;

  public isRoleAndProductsSliderOpened = false
  public tableData: any 
  public organisationId: number
  public roles = []
  public products = []
  public acceptedRoles: any
  public section: string = 'user-pending';

  constructor(
    public loaderService: LoaderService,
    private UsersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,    
  ) {}

  ngOnInit(): void {
    this.layout = new Layout('Pending Users', null, null);
    this.getPendingUserList()
    this.route.queryParams.subscribe((params) => {
    this.currentQueryParams = params;  
    });
  }

  public addTableData(e: { product: number, roles: number[] }[]) {
    if (this.tableData && this.tableData.length > 0) {
      e.forEach(newItem => {
        const existingItemIndex = this.tableData.findIndex(item => item.product === newItem.product);
        if (existingItemIndex !== -1) {
          // Product already exists, update roles if not already present
          newItem.roles.forEach(role => {
            if (!this.tableData[existingItemIndex].roles.includes(role)) {
              this.tableData[existingItemIndex].roles.push(role);
            }
          });
        } else {
          // Product does not exist, add it to the tableData
          this.tableData.push(newItem);
        }
      });
    } else {
      // If tableData is empty, simply assign the incoming data
      this.tableData = e;
    }
  }

  public removeTable() {
    this.tableData = null
  }

  public getPendingUserList() {
    this.UsersService.getPendingUsers().subscribe( (success) => {
      this.userList = success.data
      if (this.currentQueryParams.id) {
        this.onUserClick(this.currentQueryParams.id);
      }
    })
  }

  public onUserClick = (primaryKey) => {   
    this.currentPendingUser = this.userList.find(user => user.ID == +primaryKey);
    this.organisationId = this.currentPendingUser.organisationId;
    this.currentPendingUser.pendingUser = true;
    this.getRoles()
    this.getProducts(this.organisationId)
    this.detailslayout = new Layout(
      this.currentPendingUser.fullname, 
      [`ID: ${this.currentPendingUser.ID}`, `${this.currentPendingUser.email}`, `${this.currentPendingUser.jobtitle}` ], 
      null
    );
    this.router.navigate([], { queryParams: { id: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closePendingUserSlider = () => {
    this.detailslayout = null;
    this.tableData = null;
    this.getPendingUserList();
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
  }

  public onRolesClick = () => {
    this.isRoleAndProductsSliderOpened = true
  }

  public closeRolesSlider = () => {
    this.isRoleAndProductsSliderOpened = false
  }

  public dataUpdate(formData){
    if(formData.action == 'accept') {
      this.acceptRequest(formData.form);
    }
    if(formData.action == 'reject') {
      this.rejectReason();
    }
    if(formData.action == 'add') {
      this.currentPendingUser.organisationId = formData.organisation;
      this.organisationId = formData.organisation;
      this.getProducts(this.organisationId)
      this.tableData = null
    }
  }

  public acceptRequest(formData: IUserPendingForm) {   
    if (this.tableData) {
      this.transformRoles(this.tableData)
    }
    let body: IUserPendingPost = {
      logonName: this.currentPendingUser.email || null,
      fullName: this.currentPendingUser.fullname || null,
      organisationId: formData.organisationId || null,
      jobTitle: formData.jobtitle || null,
      roles: this.acceptedRoles || null,
    }
    this.UsersService.acceptUsers(this.currentPendingUser.ID, body).subscribe((success) => {
      if(success){
        this.detailslayout = null
        this.getPendingUserList();
        this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
      }
    },
    (error) => {
      console.log('Error: ', error);
    }
  )
  }

  transformRoles(tableData: any) {
    this.acceptedRoles = [];
    tableData.forEach((obj) => {
      const roles = obj.roles.map(role => ({
        productId: obj.product,
        roleType: role,
        projectId: null,
        serviceItemId: null,
        submissionId: null
    }));
    this.acceptedRoles = this.acceptedRoles.concat(roles);
    })
  }

  public rejectReason(){
    this.modalInfo = {
      item: this.currentPendingUser.fullname, 
      subText: "Please provide a reason why you would like to reject", 
      title: "Reject",
      action: "User",
      request: "Reject",
      comment: true
    }
  }
  
  public closeModal(event){
    if(event == true){
      this.deleteRequest()
      this.modalInfo = null

    }
    this.modalInfo = null
  }

  public commentText(event){
    this.commentValue = event
  }

  public deleteRequest() {
    let body = {
      message: this.commentValue
    }
    this.UsersService.deleteUsers(this.currentPendingUser.ID, body).subscribe((success) => {
      if(success){
        this.detailslayout = null
        this.getPendingUserList();
        this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
      }
    },
    (error) => {
      console.log('Error: ', error);
    }
  )
  }

  public getRoles() {
    this.UsersService.getRoles().subscribe( (success) => {
      const roles = success.roles.map(r => ({
        id: r.role_type,
        name: r.role_name
      }));   
      this.roles = roles
    },
    (error) => {
      console.log('Error: ', error);
    }
  )
  }

  public getProducts(organisationId: number) {
    this.UsersService.getProducts(organisationId).subscribe( (success) => {
      const products = success.data.map(r => ({
        id: r.productId,
        name: r.productName
      })); 
      this.products = products
    },
    (error) => {
      console.log('Error: ', error);
    }
  )
  }

}
