<div [formGroup]="form" *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div [formArrayName]="block.dataKey">
        <h5>{{ block.label }}</h5>
        <p *ngIf="block.helpText" class="text-muted" [innerHtml]="block.helpText"></p>
        <div *ngFor="let child of form.get(block.dataKey)['controls']; let i = index" [formGroupName]="i" class="row justify-content-start pt-3 rounded mb-2" [ngClass]="{'bg-dark-700 text-light': ui.darkMode, 'bg-light': !ui.darkMode}">
            <div *ngFor="let child of block.children" [class]="'col-md-' + child.colWidth + ' col-12'" style="align-self: center">
                <!-- Input, select -->
                <app-dynamic-form-input *ngIf="child.blockType == 'input'" [form]="form.get(block.dataKey)['controls'][i]" [block]="child"></app-dynamic-form-input>
                <app-dynamic-form-select *ngIf="child.blockType == 'select'" [form]="form.get(block.dataKey)['controls'][i]" [block]="child" [data]="data" (id)="idCheck($event, i)"></app-dynamic-form-select>
                <app-dynamic-form-search-input *ngIf="child.blockType == 'searchInput'" [form]="form.get(block.dataKey)['controls'][i]" [block]="child"></app-dynamic-form-search-input>
                <app-dynamic-form-dropdown *ngIf="child.blockType == 'searchDropdown'" [form]="form.get(block.dataKey)['controls'][i]" [block]="child"></app-dynamic-form-dropdown>
                <app-dynamic-form-textarea *ngIf="child.blockType == 'textarea'" [form]="form.get(block.dataKey)['controls'][i]" [block]="child"></app-dynamic-form-textarea>
                <i class="far fa-lg fa-trash-alt c-pointer text-danger" *ngIf="child.blockType == 'delete' && (rules.min != data[rules.dataKey].length && rules.min > -1)"
                    (click)="removeSelectedItem(i, child.label, child.dataKey)">
                </i>
            </div>
        </div>
    </div>
</div>
<app-popup-modal *ngIf="modalInfo" [modalInfo]="modalInfo" (action)="closeModal($event)"></app-popup-modal>