<div [formGroup]="form" *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div class="form-group">
        <div class="d-flex justify-content-between">
            <label [for]="block.dataKey" [ngClass]="{'text-light': ui.darkMode}">{{ block.label }} </label>
            <span *ngIf="block.button && data.organisation_id !== null"> 
                <span *ngFor="let item of block.button">
                    <i [class]="item.style" style="color: #003087;" [ngbTooltip]="item.text" (click)="buttonAction(item.action)"></i>  
                </span>
            </span>
        </div>
        <small [id]="block.dataKey + 'Help'" class="form-text text-muted" [innerHTML]="block.helpText"></small>
                <select #mySelect class="form-control" [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}"
                    [id]="block.dataKey" [formControlName]="block.dataKey" (ngModelChange)='onOptionSelected($event)'>
                    <ng-container *ngIf="block.optionsAreGrouped">
                        <optgroup *ngFor="let group of block.options" label="{{group.group}}">
                            <option *ngFor="let option of group.children" [ngValue]="option.id">
                                {{ option.name }}
                            </option>
                        </optgroup>
                    </ng-container>
                    <ng-container *ngIf="!block.optionsAreGrouped">
                        <ng-container *ngIf="block.options">
                            <option *ngFor="let option of block.options" [ngValue]="option.id">
                                {{ option.name }}
                            </option>
                        </ng-container>
                        <ng-container *ngIf="block.softOptions">
                            <option *ngFor="let item of data.softOptions" [ngValue]="item">
                                {{ item }}
                            </option>
                        </ng-container>
                        <ng-container *ngIf="block.submissions">
                            <option [ngValue]="null">Please select a submission...</option>
                            <option *ngFor="let item of data.submissions" [ngValue]="item.submission_id">
                                {{ item.submission_name }}
                            </option>
                        </ng-container>
                    </ng-container>
                </select>
    </div>
