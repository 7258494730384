import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";

export const OrganisationDetailBasics: IDynamicFormBlock[] = [
    {
        label: 'Organisation Basics',
        helpText: 'The section covers the basic details of the selected organisation.',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Organisation Name',
        dataKey: 'organisationName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Organisation Type Name',
        dataKey: 'organisationTypeId',
        blockType: 'select',
        colWidth: 6,
    },
    {
        label: 'Organisation Type Other',
        dataKey: 'organisationTypeOther',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    },
    {
        label: 'NHS Code',
        dataKey: 'nhsCode',
        blockType: 'input',
        inputType: 'text',
        colWidth: 4,
    },
    {
        label: 'Is Defunct',
        dataKey: 'isDefunct',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    },
    {
        label: 'Region',
        dataKey: 'regionId',
        blockType: 'select',
        colWidth: 4
    },
    {
        label: 'Postal Address',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Address 1',
        dataKey: 'postalAddress1',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    },
    {
        label: 'Address 2',
        dataKey: 'postalAddress2',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Address 3',
        dataKey: 'postalAddress3',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    },
    {
        label: 'Address 4',
        dataKey: 'postalAddress4',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Postcode',
        dataKey: 'postalAddressPc',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Save',
        dataKey: 'save',
        blockType: 'saveButton',
        colWidth: 6
    }
    
];