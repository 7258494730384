<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
  <ng-container *ngIf="!currentUser">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12">
          <div class="row mt-5 mb-3">
            <div class="col-md-7 col-12" *ngIf="layout">
              <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
            </div>
            <div class="col-md-5 col-12 d-flex">
              <div class="ml-auto">
              </div>
            </div>
          </div>
          <app-area-nav [navItems]="topLevelUserNavItems"></app-area-nav>

          <app-dynamic-table
            [data]="userList"
            [columns]="usersTableColumns"
            [tableTitle]="'userList'"
            [primaryKey]="'userId'"
            [onRowClick]="onTableRowClick"
            [userTableUpdate]="userTableUpdate"
            [changeUsersList]="changeUsersList">
          </app-dynamic-table>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Current User -->
<ng-container *ngIf="currentUser">
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div class="col-md-11 col-12">
        <div class="row mt-5 mb-3">
          <div class="col-md-7 col-12" *ngIf="layout">
            <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
          </div>
          <div class="col-md-5 col-12 d-flex">
            <div class="ml-auto">
            </div>
          </div>
        </div>

        <app-area-nav [navItems]="usersNavItems"></app-area-nav>
        <app-users-detail *ngIf="currentSection == 'details'" [currentUser]="currentUser"></app-users-detail>        
        <app-users-roles *ngIf="currentSection == 'roles'" [currentUser]="currentUser"></app-users-roles>
        <app-users-login-attempts *ngIf="currentSection == 'login-attempts'" [currentUser]="currentUser"></app-users-login-attempts>
        <app-users-activity *ngIf="currentSection == 'activity'" [currentUser]="currentUser"></app-users-activity>
      </div>
    </div>
  </div>
</ng-container>