import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Layout } from '../core/classes/layout';
import { Organisation } from '../core/classes/organisation';
import { Constants } from '../core/constants/constants';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { ILayout } from '../core/models/layout.vm';
import { INavItem } from '../core/models/nav-item.vm';
import { IOrganisation } from '../core/models/organisation.vm';
import { LoaderService } from '../core/services/loader.service';
import { OrganisationService } from '../core/services/organisation.service';
import { OrganisationNavItems, OrganisationTableColumns } from './organisation-layouts';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit, OnDestroy {
  public layout: ILayout;

  public organisationNavItems: Array<INavItem> = OrganisationNavItems;
  public organisationTableColumns: Array<IDynamicTableColumn> = OrganisationTableColumns;

  public organisationList: Array<Organisation> = new Array<Organisation>();

  public currentUrlParams: any;
  public currentYear: number;
  public currentOrganisation: IOrganisation = null;
  public currentSection: string = undefined;

  public loadingError: string = null;
  private unsubscribe = new Subject();
  
  
  constructor(
    public loaderService: LoaderService,
    private organisationService: OrganisationService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.route.params.subscribe(params => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });
   }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public routeChange(params) {
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0)
    this.currentYear = params.year || Constants.currentYear;
    this.currentSection = params.section;

    // No organisation selected, return to list
    if (this.currentSection == undefined && this.organisationList.length == 0) {
      this.getOrganisationList(this.currentYear);
    }
    // Organisation selected, get details and show section
    if (this.currentSection && !this.currentOrganisation) {
      this.getOrganisationDetails(params.id, this.currentYear);
    }
  }

  private getOrganisationList(year: number): void {
    this.currentOrganisation = null;
    this.organisationService.getOrganisations(year).pipe(takeUntil(this.unsubscribe)).subscribe(
      success => { 
        success.data.organisationList.forEach(organisation => {
          this.organisationList.push(new Organisation(organisation))
        });    
        this.organisationList.forEach((obj) => {
          // Simplify product names concatenation
          if (obj.products && obj.products.length) {
              obj.productsString = obj.products.map(p => p.productAlias).join(', ');
          } else {
              obj.productsString = '';
          }      
        // Replace empty or null values with '—'
        Object.keys(obj).forEach((key) => {
            let { [key as keyof typeof obj]: objKey } = obj;
            if (objKey === null || objKey === undefined || objKey === '' || objKey === ' ') {
                Object.defineProperty(obj, key, {
                    value: '—',
                    enumerable: true,
                    writable: true
                });
            }
          });
        });
        //
        this.layout = new Layout('Organisations', null, null);      
      },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public yearChange(year: number): void {
    if (this.currentOrganisation) {
      this.currentOrganisation = null;
      this.router.navigate(['/organisation', year, this.currentUrlParams.id, this.currentUrlParams.section]);
    } else {
      this.organisationList = new Array<Organisation>();
      this.router.navigate(['/organisation', year]);
    }
  }

  public onTableRowClick = (primaryKey: string): void => {
    this.router.navigate(['organisation', this.currentYear, primaryKey, 'details']);
  }

  private getOrganisationDetails(organisationId: number, year: number): void {
    this.organisationService.getOrganisation(organisationId, year).subscribe(
      success => { 
        this.currentOrganisation = new Organisation(success.data.organisationDetails, year);
        this.layout = new Layout(
          this.currentOrganisation.organisationName,
          [ `ID: ${this.currentOrganisation.organisationId}`, `Category: ${this.currentOrganisation.organisationName}` ],
          `/organisation/${this.currentYear}`
        );
      },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

}
