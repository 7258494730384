import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISuccessResponse } from 'src/app/core/models/apiResponses';

import { Project } from 'src/app/core/classes/project';
import { ProjectService } from 'src/app/core/services/project.service';

import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { INavItem } from 'src/app/core/models/nav-item.vm';

import { Store } from '@ngrx/store';
import { FlattenPipe } from 'ngx-pipes';
import { Observable } from 'rxjs';
import { IDynamicFormRules } from 'src/app/core/models/dynamic-form-rules.vm';
import { IProjectPeerGroup } from 'src/app/core/models/project-peer-group';
import { StoredProcedures } from 'src/app/core/models/stored-procedures';
import {
  SubmissionDataDetails,
  SubmissionDetailsEdit,
} from 'src/app/core/models/submissionData';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectDetailNavItems } from '../project-layouts';
import {
  ProjectDetailBasics,
  ProjectDetailDates,
  ProjectDetailGuidance,
  ProjectDetailRoles,
  ProjectDetailRules,
  ProjectDetailServiceItems,
  projectDetailsPeerGroupBlocks,
  ProjectDetailSurvey,
  TranslatedProjectDetailSurvey,
} from './project-detail-layouts';
@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  providers: [FlattenPipe],
})
export class ProjectDetailComponent implements OnChanges {
  @Input() public currentProject: Project;

  public loginLoading: boolean = false;
  public loginError: string;

  public currentScope: string = null;

  private currentQueryParams: any;

  public projectDetailNavItems: Array<INavItem> = ProjectDetailNavItems;

  public projectDetailBasicsBlocks: Array<IDynamicFormBlock> =
    ProjectDetailBasics;
  public projectDetailRolesBlocks: Array<IDynamicFormBlock> =
    ProjectDetailRoles;
  public projectDetailDatesBlocks: Array<IDynamicFormBlock> =
    ProjectDetailDates;
  public projectDetailServiceItemBlocks: Array<IDynamicFormBlock> =
    ProjectDetailServiceItems;
  public projectDetailGuidanceBlocks: Array<IDynamicFormBlock> =
    ProjectDetailGuidance;
  public projectDetailSurveyBlocks: Array<IDynamicFormBlock> =
    ProjectDetailSurvey;
  public translatedProjectDetailSurveyBlock: Array<IDynamicFormBlock> =
    TranslatedProjectDetailSurvey;
  public projectDetailPeerGroupBlocks: Array<IDynamicFormBlock> =
    projectDetailsPeerGroupBlocks;
  public projectDetailRules: IDynamicFormRules = ProjectDetailRules;

  public loadingError: string = null;
  public alert: Object;
  public maxResponses: number;
  public useTranslatedList: boolean;

  public storedProcedures: StoredProcedures;

  projectName$: Observable<string>;

  constructor(
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public flatten: FlattenPipe,

    private store: Store<{ projectName: string }>
  ) {}

  ngOnChanges() {
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
      this.scopeChange(this.currentQueryParams, this.currentProject);
    });
  }

  public updateSubmissionDetails(sub: SubmissionDetailsEdit, projectId: number) {
    let currentYear = +this.route.snapshot.params.year;
    let body = sub;
    this.projectService.updateSubmissionDetails(body, projectId).subscribe(
      (success: ISuccessResponse) => {
        this.alert = {
          alertClass: 'success',
          fadeOut: true,
          message: "Project details updated successfully.",
        };
        this.getProjectDetails(this.currentProject.projectId, currentYear);
      },
      (error) => {
        console.log({ error });
        this.loadingError = error.error.error.message || error.statusText;
      }
    );
  }

  submissionDetailsUpdate(event: SubmissionDataDetails): void {
    let {
      projectName,
      isVisible,
      projectPhoneNo,
      projectDescription,
      registrationAvailable,
      hasOrganisationQuestions,
      dataCollectionOpen,
      outputsAvailable,
      projectId,
    } = event.row;

    const fieldMap = {
      'Project Name': projectName,
      Visibility: isVisible,
      'Project Phone Number': projectPhoneNo,
      'Project Description': projectDescription,
      'Registration Available': registrationAvailable,
      'Organisation Questions': hasOrganisationQuestions,
      'Data Collection Open': dataCollectionOpen,
      'Outputs Available': outputsAvailable,
      'Project ID': projectId,
    };

    // Find missing fields
    const missingFields = Object.entries(fieldMap)
      .filter(
        ([_, value]) => value === undefined || value === null || value === ''
      )
      .map(([key, _]) => key);
    if (missingFields.length > 0) {
      // Show an alert if any field is missing
      this.alert = {
        message: `Please fill in all required fields: ${missingFields.join(
          ', '
        )}`,
        alertClass: 'danger',
        fadeOut: false,
      };
      console.log('Missing fields:', missingFields);
      return; // Exit the function if validation fails
    }

    if(fieldMap['Project Phone Number']){
      let number = fieldMap['Project Phone Number'].toString()
      if(number.length < 10 || number.length > 11){
        projectPhoneNo = undefined
        this.alert = {
          message: `Please enter a valid phone number`,
          alertClass: 'danger',
          fadeOut: true,
        };
        return; // Exit the function if validation fails
      }
    }

    const editableProperties: SubmissionDetailsEdit = {
      projectName,
      isVisible,
      projectPhoneNo,
      projectDescription,
      registrationAvailable,
      hasOrganisationQuestions,
      dataCollectionOpen,
      outputsAvailable,
      projectId,
    };

    editableProperties
      ? this.updateSubmissionDetails(editableProperties, projectId)
      : null;
  }

  dataUpdate(data: any, key: keyof Project) {
    if (key == 'guidance') {
      this.updateGuidance(data, key);
    } else if (key == 'projectRoles') {
      this.updateProjectRoles(data, key);
    } else if (key == 'serviceItems') {
      this.serviceUpdate(data, key);
    } else if (key == 'surveys') {
      this.updateExternalSurveySetting(data, key);
      this.updateExternalSurvey(data, key);
    } else if (key == 'projectDates') {
      this.datesUpdate(data,key)
    }
  }

  updateGuidance(data: any, key: keyof Project) {
    if (data.action == 'remove') {
      this.projectService
        .deleteProjectGuidance(
          this.currentProject.projectId,
          this.currentProject.guidance[data.row].projectGuidanceId
        )
        .subscribe(
          (success) => {
            this.getProjectGuidance(this.currentProject.projectId);
            this.alert = {
              message: 'Deletion Successful',
              alertClass: 'success',
              fadeOut: true,
            };
          },
          (error) => {
            this.alert = {
              message: 'Deletion Failed',
              alertClass: 'danger',
              fadeOut: true,
            };
            console.log('Error: ', error);
          }
        );
    } else if (data.action == 'add') {
      this.projectService
        .putProjectGuidance(this.currentProject.projectId, data.row.guidance)
        .subscribe(
          (success) => {
            this.getProjectGuidance(this.currentProject.projectId);
            this.alert = {
              message: 'Update Successful',
              alertClass: 'success',
              fadeOut: true,
            };
          },
          (error) => {
            this.alert = {
              message: 'Addition Failed',
              alertClass: 'danger',
              fadeOut: true,
            };
            console.log('Error: ', error);
          }
        );
    }
  }

  updateExternalSurvey(data: any, key: keyof Project) {
    if (data.action == 'add') {
      data.row.surveys.forEach((survey) => {
        survey['surveyOpenDate'] = new Date(
          survey.surveyOpenDate
        ).toISOString();
      });
      data.row.surveys.forEach((survey) => {
        survey['surveyCloseDate'] = new Date(
          survey.surveyCloseDate
        ).toISOString();
      });
      let body = data.row.surveys.map((survey) => ({
        surveyName: survey.surveyName,
        surveyId: survey.surveyId,
        projectId: survey.projectId,
        groupLevelId: survey.groupLevelId,
        surveyOpenDate: survey.surveyOpenDate,
        surveyCloseDate: survey.surveyCloseDate,
        isVisible: survey.isVisible,
      }));
      this.projectService.putProjectSurveys(body).subscribe(
        (success) => {
          this.getProjectSurveys(this.currentProject.projectId);
          this.alert = {
            message: 'Update Successful',
            alertClass: 'success',
            fadeOut: true,
          };
        },
        (error) => {
          this.alert = {
            message: 'Addition Failed',
            alertClass: 'danger',
            fadeOut: true,
          };
          console.log('Error: ', error);
        }
      );
    }
  }

  updateExternalSurveySetting(data: any, key: keyof Project) {
    let intro = data.row.surveys.map((survey) => ({
      settingId: survey.surveyIntroSettingId,
      surveyId: survey.surveyId,
      settingName: 'surveyIntro',
      settingValue: survey.surveyIntro,
    }));

    let introShort = data.row.surveys.map((survey) => ({
      settingId: survey.surveyIntroShortSettingId,
      surveyId: survey.surveyId,
      settingName: 'surveyIntroShort',
      settingValue: survey.surveyIntroShort,
    }));

    let maxResponses = data.row.surveys.map((survey) => ({
      settingId: survey.maxResponsesSettingId,
      surveyId: survey.surveyId,
      settingName: 'maxResponses',
      settingValue: survey.maxResponses,
    }));

    let body = [...intro, ...introShort, ...maxResponses];

    this.projectService.putProjectSurveysSettings(body).subscribe(
      (success) => {
        this.getProjectSurveys(this.currentProject.projectId);
        this.alert = {
          message: 'Update Successful',
          alertClass: 'success',
          fadeOut: true,
        };
      },
      (error) => {
        this.alert = {
          message: 'Addition Failed',
          alertClass: 'danger',
          fadeOut: true,
        };
        console.log('Error: ', error);
      }
    );
  }

  private getProjectRoles(projectId: number, year: number): void {
    this.projectService.getProject(projectId, year).subscribe(
      (success) => {
        this.currentProject = new Project(success.data.projectList[0]);
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private updateProjectRoles(data: any, key: keyof Project) {
    let projectId: number = this.currentProject.projectId;
    let currentYear = +this.route.snapshot.params.year;

    if (data.action == 'remove') {
      let roleToDelete =
        this.currentProject.projectRoles[data.row].projectRoleId;
      this.projectService.removeProjectRoles(roleToDelete).subscribe(
        (success) => {
          this.getProjectRoles(projectId, currentYear);
          this.alert = {
            message: 'Role Successfully Deleted',
            alertClass: 'success',
            fadeOut: true,
          };
        },
        (error) => {
          this.alert = {
            message: `Failed to delete role. Error msg: ${error}`,
            alertClass: 'danger',
            fadeOut: true,
          };
          console.log('Error: ', error);
        }
      );
    }

    if (data.action == 'add') {
      let rolesToAdd = data.row[key];
      this.projectService.submitProjectRoles(projectId, rolesToAdd).subscribe(
        (success) => {
          this.getProjectRoles(projectId, currentYear);
          this.alert = {
            message: 'Roles Successfully Update',
            alertClass: 'success',
            fadeOut: true,
          };
        },
        (error) => {
          this.alert = {
            message: `Failed to add roles. Error msg: ${error.error.message}`,
            alertClass: 'danger',
            fadeOut: true,
          };
          this.alert = {
            message: 'Ensure all fields have been filled',
            alertClass: 'danger',
            fadeOut: false,
          };
          console.log('Error: ', error);
        }
      );
    }
  }

  serviceUpdate(data: any, key: keyof Project) {
    if (data.row.serviceItems) {
      if (data.action == 'add') {
        let currentYear = +this.route.snapshot.params.year;
        // converting form values from string to numbers
        data.row.serviceItems.forEach((s) => {
          if (s.serviceItemId) {
            s.serviceItemId = Number(s.serviceItemId);
          }
          if (s.displaySequence) {
            s.displaySequence = Number(s.displaySequence);
          }
        });
        this.projectService
          .submitProjectServiceItems(
            this.currentProject.projectId,
            currentYear,
            data.row.serviceItems
          )
          .subscribe(
            (success) => {
              this.currentProject.serviceItems = success.data;
              this.alert = {
                message: 'Submitted successfully',
                alertClass: 'success',
                fadeOut: true,
              };
            },
            (error) => {
              this.alert = {
                message: error.message,
                alertClass: 'danger',
                fadeOut: true,
              };
              console.log('Error: ', error);
            }
          );
      }
    }
  }

  private scopeChange(params: any, currentProject: Project): void {
    this.currentScope = params.scope || 'basics';
    if (this.currentScope == 'services' && !this.currentProject.serviceItems) {
      this.getProjectServiceItems(currentProject.projectId);
    }
    if (this.currentScope == 'guidance' && !this.currentProject.guidance) {
      this.getProjectGuidance(currentProject.projectId);
    }
    if (this.currentScope == 'surveys' && !this.currentProject.surveys) {
      this.getProjectSurveys(currentProject.projectId);
    }
    if (this.currentScope == 'peer-groups' && !this.currentProject.peerGroups) {
      this.getProjectPeerGroups(currentProject.projectId);
    }
  }

  datesUpdate(data: any, key: keyof Project) {
    if (data.row.projectDates) {
      if (data.action == 'add') {
        let currentYear = +this.route.snapshot.params.year;
        let projectDates = { ...data.row.projectDates[0], year: currentYear };
        this.projectService
          .submitProjectDates(this.currentProject.projectId, projectDates)
          .subscribe(
            (success) => {
              this.getProjectDetails(
                this.currentProject.projectId,
                currentYear
              );
              this.alert = {
                message: 'Submitted successfully',
                alertClass: 'success',
                fadeOut: true,
              };
            },
            (error) => {
              this.alert = {
                message: error.error.error.message,
                alertClass: 'danger',
                fadeOut: true,
              };
              console.log('Error: ', error);
            }
          );
      }
    }
  }

  private getProjectDetails(projectId: number, year: number): void {
    this.projectService.getProject(projectId, year).subscribe(
      (success) => {
        this.currentProject = new Project(success.data.projectList[0]);
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private getProjectServiceItems(projectId: number): void {
    this.projectService.getProjectServiceItems(projectId).subscribe(
      (success) => {
        this.currentProject.serviceItems = success.data.serviceItemList;
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private getProjectGuidance(projectId: number): void {
    this.projectService.getProjectGuidance(projectId).subscribe(
      (success) => {
        this.currentProject.guidance = success.data.projectGuidance;
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private getProjectSurveys(projectId: number): void {
    this.projectService.getProjectSurveys(projectId).subscribe(
      (success) => {
        let newSurveys = this.currentProject.setExternalSurveySettings(
          success.data.externalSurveyList
        );
        this.currentProject.surveys = newSurveys;
        this.currentProject.surveys.forEach((survey) => {
          if (
            survey.settingList.find(
              (setting) => setting.settingName == 'translation'
            ).settingValue == 'true'
          ) {
            this.useTranslatedList = true;
          } else {
            this.useTranslatedList = false;
          }
        });
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private getProjectPeerGroups(projectId: number): void {
    this.projectService.getProjectPeerGroups(projectId).subscribe(
      (success) => {
        let peerGroups: Array<IProjectPeerGroup> = this.flatten.transform(
          Object.values(success.data.peerGroupList)
        );
        this.currentProject.peerGroups = peerGroups;
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }
}
