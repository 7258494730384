import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const DocumentsDynamicTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Category',
        dataKey: 'documentCategoryName',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'File Name',
        dataKey: 'fileName',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Event Name',
        dataKey: 'eventName',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 7,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Date',
        dataKey: 'uploadTime',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: true,
        filterDateTime: true,
        dateFormat: 'MMM D Y, h:mm A'
    },
    {
        header: 'Downloads',
        dataKey: 'downloads',
        percWidth: 5,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Visible',
        dataKey: 'isVisible',
        percWidth: 3,
        sortable: true,
        groupable: true,
        searchable: false
    }
];

export const DocumentsDynamicForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Dynamic Documents',
        blockType: 'header',
    },
    {
        label: 'File Name',
        dataKey: 'fileName',
        blockType: 'input',  
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Upload Date',
        dataKey: 'uploadTime',
        blockType: 'input',    
        colWidth: 6,
        optionsType: 'services',
        disabled: true
    },
    {
        label: 'Document Name',
        dataKey: 'documentName',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Display Sequence',
        dataKey: 'displaySequence',
        blockType: 'input',
        colWidth: 3,
    },
    {
        label: 'Created By',
        dataKey: 'createdBy',
        blockType: 'searchDropdown',
        colWidth: 3,
        optionsType: 'adminUsers'
    },
    {
        label: 'Document Category Id',
        dataKey: 'documentCategoryId',
        blockType: 'select',
        options: [
            { id: 1, name: 'Report' },
            { id: 2, name: 'CaseStudy' },
            { id: 3, name: 'Presentation' },
            { id: 4, name: 'Toolkit' },
            { id: 6, name: 'Bespoke' },
            { id: 7, name: 'Specification' },
        ],
        colWidth: 3
    },
    {
        label: 'Visible',
        dataKey: 'isVisible',
        blockType: 'select',
        colWidth: 3,
        options: [{ id: 'Y', name: 'Yes' },{ id: 'N', name: 'No' }]
    }
]

export const DocumentsDynamicTableAccessLogColumns: IDynamicTableColumn[] = [
    {
        header: 'Organisation',
        dataKey: 'organisationId',
        percWidth: 50,
        sortable: true,
        groupable: true,
        searchable: true,
        organisation: true
    },
    {
        header: 'Full Name',
        dataKey: 'fullName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Time',
        dataKey: 'time',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true,
        dateFormat: 'MMM D Y, h:mm A'
    },
];

export const UploadDocumentsDynamicForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Document Name',
        dataKey: 'documentName',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Display Sequence',
        dataKey: 'displaySequence',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4,
    },
    {
        label: 'Document Category',
        dataKey: 'documentCategoryId',
        blockType: 'select',
        options: [
            { id: 1, name: 'Report' },
            { id: 2, name: 'CaseStudy' },
            { id: 3, name: 'Presentation' },
            { id: 4, name: 'Toolkit' },
            { id: 6, name: 'Bespoke' },
            { id: 7, name: 'Specification' },
        ],
        colWidth: 4
    },
    {
        label: 'Visible',
        dataKey: 'isVisible',
        blockType: 'select',
        colWidth: 4,
        options: [{ id: 'Y', name: 'Yes' },{ id: 'N', name: 'No' }]
    },
    {
        blockType: 'saveButton',
        dataKey: 'documentName',
        dataKeys: ['documentName', 'displaySequence', 'documentCategoryId', 'isVisible'],
        label: 'Save',
        action: 'create'
    }
]
