import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss']
})
export class DynamicFormInputComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public block: IDynamicFormBlock;
  public copiedMessage: string = null;
  darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void { }

  async copyToClipboard(id: string) {
    var range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.copiedMessage = 'Copied to clipboard'
  }

}
